@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

// Custom variables
$bs-border-width: 1px;
$bs-border-active-width: 2px;
$by-neutral-color-black: #000;
$by-neutral-color-white: #fff;
$black: #000;
$paypal-bg: #ffc452;
$enable-negative-margins: true;
$navbar-height: 74px;
//Text colors//
$by-light-text-color-solid: rgba($by-neutral-color-black, 1);
$by-light-text-color-secondary: rgba($by-neutral-color-black, 0.7);
$by-light-text-color-tertiary: rgba($by-neutral-color-black, 0.6);
$by-light-text-color-solid-inverse: rgba($by-neutral-color-white, 1);
$by-light-text-color-secondary-inverse: rgba($by-neutral-color-white, 0.7);
$by-light-text-color-tertiary-inverse: rgba($by-neutral-color-white, 0.6);

$by-light-text-color-primary: rgba($by-neutral-color-black, 0.9);
$by-light-text-color-disabled: rgba($by-neutral-color-black, 0.38);
$by-light-text-color-primary-inverse: rgba($by-neutral-color-white, 0.9);
$by-light-text-color-disabled-inverse: rgba($by-neutral-color-white, 0.38);
$by-light-text-color-interactive-inverse: #72a8e9;

//Neutral colors//
$by-light-neutral-color-white: #fff;
$by-light-neutral-color-01: #f4f4f4;
$by-light-neutral-color-02: #e0e0e0;
$by-light-neutral-color-03: #c6c6c6;
$by-light-neutral-color-04: #a8a8a8;
$by-light-neutral-color-05: #8d8d8d;
$by-light-neutral-color-06: #6f6f6f;
$by-light-neutral-color-07: #525252;
$by-light-neutral-color-08: #393939;
$by-light-neutral-color-09: #262626;
$by-light-neutral-color-10: #161616;
$by-light-neutral-color-black: #000;
//Misc colors//
//Chart colors//
$by-light-shadow-01: 0 1px 3px 0 rgba($black, 0.12), 0 1px 1px 0 rgba($black, 0.14),
  0px 2px 1px 0px rgba($black, 0.2);
$by-light-shadow-02: 0 3px 1px 0 rgba($black, 0.2), 0 2px 2px 0 rgba($black, 0.14),
  0 4px 4px 0 rgba($black, 0.25);
$by-light-shadow-03: 0 1px 10px 0 rgba($black, 0.12), 0 4px 5px 0 rgba($black, 0.14),
  0 2px 4px 0 rgba($black, 0.2);
$by-light-shadow-04: 0 3px 14px 0 rgba($black, 0.12), 0 8px 10px 0 rgba($black, 0.14),
  0 5px 5px 0 rgba($black, 0.2);
$by-light-shadow-05: 0 6px 30px 0 rgba($black, 0.12), 0 16px 24px 0 rgba($black, 0.14),
  0 8px 10px 0 rgba($black, 0.2);
$by-light-shadow-06: 0 9px 46px 0 rgba($black, 0.12), 0 24px 38px 0 rgba($black, 0.14),
  0 11px 15px 0 rgba($black, 0.2);
// Override variables for bootstrap
$grid-columns: 16;
$grid-gutter-width: 2rem !default;
$grid-row-columns: 8 !default;

$grid-breakpoints: (
  xs: 0,
  sm: 320px,
  md: 672px,
  lg: 1056px,
  xl: 1312px,
  xxl: 1584px,
  xxxl: 1872px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 640px,
  md: 1024px,
  lg: 1280px,
  xl: 1536px,
  xxl: 1792px,
  xxxl: 1792px,
) !default;
// scss-docs-end container-max-widths

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: $by-light-neutral-color-white;
$gray-100: $by-light-neutral-color-01;
$gray-200: $by-light-neutral-color-02;
$gray-300: $by-light-neutral-color-03;
$gray-400: $by-light-neutral-color-04;
$gray-500: $by-light-neutral-color-05;
$gray-600: $by-light-neutral-color-06;
$gray-700: $by-light-neutral-color-07;
$gray-800: $by-light-neutral-color-08;
$gray-900: $by-light-neutral-color-09;
$black: $by-light-neutral-color-black;
// scss-docs-end gray-color-variables

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 5,
  8: $spacer * 6,
  9: $spacer * 8,
  10: $spacer * 10,
) !default;
// scss-docs-end spacer-variables-maps

// scss-docs-start box-shadow-variables
$box-shadow: $by-light-shadow-03;
$box-shadow-sm: $by-light-shadow-01;
$box-shadow-lg: $by-light-shadow-05;
$box-shadow-1: $by-light-shadow-01;
$box-shadow-2: $by-light-shadow-02;
$box-shadow-3: $by-light-shadow-03;
$box-shadow-4: $by-light-shadow-04;
$box-shadow-5: $by-light-shadow-05;
$box-shadow-6: $by-light-shadow-06;

$box-shadow-map: (
  1: $box-shadow-1,
  2: $box-shadow-2,
  3: $box-shadow-3,
  4: $box-shadow-4,
  5: $box-shadow-5,
  6: $box-shadow-6,
);

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5 !default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base * 0.875 !default;
$h7-font-size: $font-size-base * 0.75 !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  7: $h7-font-size,
) !default;
// scss-docs-end font-sizes

// scss-body-font-sizes
$b1: $font-size-base * 1.75;
$b2: $font-size-base * 1.25;
$b3: $font-size-base;
$b4: $font-size-base * 0.875;
$b5: $font-size-base * 0.75;

$body-font-sizes-map: (
  1: $b1,
  2: $b2,
  3: $b3,
  4: $b4,
  5: $b5,
);

// Body
//
// Settings for the `<body>` element.
$body-bg: #ffffff;
$disabled-bg: #00000009;
$body-color: #000000e5;

// Components
//
// Define common padding and border radius sizes and more.
$component-active-bg: #dce9fd;

// scss-docs-start border-variables
$border-width: 1px !default;
$border-width-2: 2px !default;
$border-color-contrast: #0000006b;
// scss-docs-end border-variables

// scss-docs-start input-btn-variables
$input-btn-padding-y: 11px !default;
$input-btn-padding-x: 15px !default;

$input-btn-padding-y-sm: 7px !default;
$input-btn-padding-x-sm: 15px !default;

$input-btn-padding-y-lg: 15px !default;
$input-btn-padding-x-lg: 15px !default;

// scss-docs-end input-btn-variables

// scss-docs-start form-input-variables
$input-bg: $body-bg !default;
$input-disabled-color: null !default;
$input-disabled-bg: $disabled-bg;
$input-disabled-border-color: null !default;
$input-color: $body-color !default;
$input-border-color: $border-color-contrast !default;
$input-focus-bg: $input-bg !default;
$input-focus-border-color: tint-color($component-active-bg, 50%) !default;
$input-focus-color: $input-color !default;
// scss-docs-end form-input-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: 31px !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: 31px !default;

$btn-border-width: $border-width !default;

// Allows for customizing button radius independently from global border radius
$btn-hover-bg-shade-amount: 11% !default;
$btn-hover-border-shade-amount: 11% !default;
$btn-active-bg-shade-amount: 18% !default;
$btn-active-border-shade-amount: 18% !default;
// scss-docs-end btn-variables

// Spinners

$spinner-border-width: 0.125rem !default;
$spinner-border-width-sm: 0.125rem !default;

// utilities
@each $size, $shadow in $box-shadow-map {
  .shadow-#{$size} {
    box-shadow: $shadow;
  }
}

@each $size, $b in $body-font-sizes-map {
  .b-#{$size} {
    font-size: $b;
  }
}
