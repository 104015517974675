@import "app/styles/abstracts/shared-variables";

.notFoundWrapper {
  background-color: var(--by-light-surface-color-blue);
  height: 100vh;
  color: var(--white);
  display: flex;
  align-items: center;
}

.notFoundInner {
  margin-left: 1.5rem;
  span {
    font-weight: 100;
    color: var(--white);
    font-size: 10rem;
    line-height: 10rem;
  }

  @include media-breakpoint-up(md) {
    margin-left: 4rem;
  }

  h1 {
    color: var(--white);
  }
}